import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CLHbid = makeShortcode("CLHbid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`En Bloc Process`}</h1>
    <p>{`The ‘en bloc’ aspect of `}<CLHbid mdxType="CLHbid" />{` offers bidders an alternative option to acquire an entire farm or ranch (or predetermined designated section of the farm or ranch). Under standard auction or tender, a bidder is required to be high on 100% of the parcels to acquire the entire farm or ranch.`}</p>
    <p>{`Being high on every parcel can be problematic for various reasons including:
a) only the high bidder on the first parcel then has a chance to acquire the entire farm or ranch and b) the chances of being high on every parcel is often challenging with parties willing to pay a premium for small parcels across from their farms etc.`}</p>
    <p>{`At `}<CLHbid mdxType="CLHbid" />{`, we still offer bidders the standard option of being high on every parcel if they wish to buy the entire farm or ranch. Also, however, we offer the ‘en bloc’ track which is a second round of bidding after the conclusion of the first round of bidding on all specific parcels. To be fair to those bidders just wanting specific parcels, we don’t make it easy for the ‘en bloc’ bidders. Not only must they have participated in round one, they also must meet certain requirements plus be willing to pay a minimum premium (the Starting Bid for ‘en bloc’) to bid ‘en bloc’ in round two.`}</p>
    <p>{`The benefits of the ‘en bloc’ to a bidder wanting the entire farm or ranch mean a) they can be outbid at the start of the sale but still remain eligible to later buy the entire farm or ranch b) it allows them to get around the difficulty associated with being high on every single parcel and c) they know if they go to round two in the ‘en bloc’ they are never ‘stuck’ with just a part of the farm or ranch – they know they either will get all or none if bidding in round two.`}</p>
    <p>{`The Starting Bid for the ‘en bloc’ is the premium amount listed in addition to the total of all bids for round one. For the purposes of the ‘en bloc’, for parcels not receiving Starting Bids in round one, the Starting Bid number for that parcel is the number then used in adding up the bids for round one.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      